import React from 'react';
/// Components
import Layout from '../components/layout';
import SubscribeForm from '../components/subscribe-form';
import Seo from '../components/seo';

const GCAFormDestinations = () => (
    <Layout>
        <Seo title='Subscribe Form' />
        <SubscribeForm
            source='gca-website'
            title='Stay up to date'
            subtitle='Get the latest news and offers delivered straight to your inbox, plus hear more about Gold Coast Airport’s exciting travel destinations.'
        />
    </Layout>
);

export default GCAFormDestinations;
